<template>
  <div class="page-manager-asset">
    <b-card title="Quản lý tài sản">
      <div class="manager-asset-search">
        <b-row>
          <b-col lg="4" md="4" xs="12" sm="3">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select label="organizationBranchName" @input="getListAllAsset" v-model="modelSearchAsset.organizationBranchId" :options="listDepartment" :reduce="(department) => department.id">
                <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Trạng thái tài sản</label>
              <v-select label="name" v-model="modelSearchAsset.status" @input="getListAllAsset" :options="listStatusAsset" :reduce="(status) => status.value">
                <span slot="no-options" @click="$refs.select.open = false">
                  Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Trạng thái cấp phát</label>
              <v-select label="name" v-model="modelSearchAsset.assignStatus" @input="getListAllAsset" :options="listAllocation" :reduce="(status) => status.value">
                <span slot="no-options" @click="$refs.select.open = false">
                  Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="3" xs="12" sm="3">
            <div class="acction-search">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
        <b-row>
          <b-col lg="12" md="12" xs="12" sm="12">
            <div class="add-asset mt-1">
              <b-button :to="{ name: 'add-asset'}"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Thêm mới</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      <b-row>
        <b-col lg="12" sm="12" xs="12" md="12">
          <div class="data-table-asset">
            <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
            <b-table :items="listAllAsset"
                     :fields="fieldsAsset"
                     :per-page="perPage"
                     :current-page="currentPage"
                     class="mb-0">
              <template #cell(index)="data">
                <span>{{data.index + 1}}</span>
              </template>
              <template #cell(status)="data">
                <b-badge v-if="data.value === 0" variant="success">
                  Hoạt động
                </b-badge>
                <b-badge v-if="data.value === 1" variant="warning">
                  Sửa chữa
                </b-badge>
                <b-badge v-if="data.value === 2" variant="danger">
                  Hỏng
                </b-badge>
              </template>
              <template #cell(assignStatus)="data">
                <b-badge v-if="data.value === 1" variant="success">
                  Đã cấp phát
                </b-badge>
                <b-badge v-if="data.value === 0" variant="warning">
                  Chưa cấp phát
                </b-badge>
              </template>
              <template #cell(action)="data">
                <b-button v-if="data.item.assignStatus === 0 && data.item.status === 0" @click="allocationAssetEmployee(data.item)" v-b-tooltip.hover.top="'Cấp phát'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-info"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="UserCheckIcon" />
                </b-button>
                <b-button v-if="data.item.assignStatus < 1" @click="updateAsset(data.item)" v-b-tooltip.hover.top="'Cập nhật'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-success"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-if="data.item.assignStatus < 1" @click="deleteAsset(data.item)" v-b-tooltip.hover.top="'Xóa'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <div class="pagination-asset-table mt-2">
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listAllocation: [
        {
          id: 1,
          name: 'Chưa cấp phát',
          value: 0,
        },
        {
          id: 2,
          name: 'Đã cấp phát',
          value: 1,
        },
      ],
      listStatusAsset: [
        {
          id: 1,
          name: 'Hoạt động',
          value: 0,
        },
        {
          id: 2,
          name: 'Sửa chữa',
          value: 1,
        },
        {
          id: 3,
          name: 'Hỏng',
          value: 2,
        },
      ],
      listAllAsset: [],
      perPage: 10,
      currentPage: 1,
      modelSearchAsset: {
        assignStatus: null,
        deviceType: 0,
        organizationBranchId: null,
        status: null,
      },
      totalCount: 0,
      fieldsAsset: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-table-index',
        },
        {
          key: 'type', label: 'Thiết bị', thClass: 'custom-th-table-type',
        },
        {
          key: 'model', label: 'Số serial', thClass: 'custom-th-table-serial',
        },
        {
          key: 'status', label: 'Tt vật dụng', thClass: 'custom-th-table-status',
        },
        {
          key: 'assignStatus', label: 'Tt cấp phát', thClass: 'custom-th-table-status',
        },
        {
          key: 'importDate', label: 'Thời gian', thClass: 'custom-th-table-importdate',
        },
        {
          key: 'action', label: 'Hành động', thClass: 'custom-th-table-action',
        },
      ],
    }
  },
  methods: {
    getListAllAsset() {
      this.$crm.post('device/find-by-condition', this.modelSearchAsset).then(res => {
        this.listAllAsset = res.data
        this.totalCount = this.listAllAsset.length
        for (let i = 0; i < this.listAllAsset.length; i++) {
          if (this.listAllAsset[i].importDate) {
            this.listAllAsset[i].importDate = moment(this.listAllAsset[i].importDate).format('DD-MM-YYYY')
          }
        }
      })
    },
    getListAllDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    updateAsset(item) {
      this.$router.push({
        name: 'update-asset',
        query: {
          id: item.id,
          name: item.type,
        },
      })
    },
    deleteAsset(item) {
      this.$swal({
        title: 'Xóa tài sản',
        text: `Bạn có muốn xóa tài sản ${item.type}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`device/delete/${item.id}`).then(res => {
            this.$swal({
              icon: 'success',
              title: 'Xóa tài sản',
              text: `Xóa tài sản ${item.type} thành công`,
              showConfirmButton: false,
              timer: 1500,
            })
            this.getListAllAsset()
          })
        }
      })
    },
    allocationAssetEmployee(item) {
      this.$router.push({
        name: 'allocation-asset',
        query: {
          idAsset: item.id,
          nameAsset: item.type,
          organizationBranchId: item.organizationBranchId,
        },
      })
    },
  },
  computed: {
    rows() {
      return this.listAllAsset.length
    },
  },
  created() {
    this.getListAllAsset()
    this.getListAllDepartment()
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/manager-asset.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
